
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  width: 0px;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* deactivate svg icon */

/*#deactivate-x-icon {*/
/*  fill: red;*/
/*}*/


@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-Black.ttf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-ExtraBold.ttf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-SemiBold.ttf);
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-Medium.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-Regular.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gilroy";
  src: url(config/fonts/Gilroy-Light.ttf);
  font-weight: 300;
  font-style: normal;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}